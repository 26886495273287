<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        v-if="true"
        :page-title="getModuleConfig('page_title')"
        :page-image="getModuleConfig('page_image')"
        :page-kv-image="getModuleConfig('page_kv_image')"
        :header-section="getModuleConfig('header_section')"
        :meta="meta"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { themeColorFn } from "@/mixins/liff/themeColor";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import outerMixin from "@/mixins/liff/outer";

export default {
  components: {
    Header,
  },
  mixins: [themeColorFn({ themeConfigPage: 'liff_outer' }), outerMixin],
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);

  &__content {
    margin: 10px 20px;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;

    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}
</style>
